import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-719f6cf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  class: "is-default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.addressItem)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["addresses", {'is-default-address': _ctx.addressItem.isDefault}]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickAddress && _ctx.clickAddress(...args)), ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: "icon_user"
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.addressItem.firstname) + _toDisplayString(_ctx.addressItem.lastname), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: "icon_phone"
          }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.addressItem.mobile), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Icon, {
            class: "icon",
            icon: "icon_address"
          }),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.addressItem.address1) + " " + _toDisplayString(_ctx.addressItem.address2||'') + ", " + _toDisplayString(_ctx.addressItem.city) + ", " + _toDisplayString(_ctx.addressItem.state) + " " + _toDisplayString(_ctx.addressItem.zipcode) + ", " + _toDisplayString(_ctx.addressItem.country), 1)
        ]),
        (_ctx.isShowEdit)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navAddressDetail(_ctx.addressItem.id)), ["stop"])),
              class: "edit-address"
            }))
          : _createCommentVNode("", true),
        (_ctx.countryInfo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "country-icon",
              src: _ctx.countryInfo.icon
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.addressItem.isDefault)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_Icon, {
                class: "icon",
                icon: "icon_default_hook"
              }),
              _createTextVNode("默认地址 ")
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}