import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9c4233a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "footer-ctrl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressItem = _resolveComponent("AddressItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressList, (item) => {
        return (_openBlock(), _createBlock(_component_AddressItem, {
          class: "g-card",
          key: item.id,
          "address-item": item,
          onClickAddress: _ctx.clickAddress
        }, null, 8, ["address-item", "onClickAddress"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "g-button g-button-primary",
        "hover-class": "hover",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navAddressDetail()))
      }, " 添加地址 ")
    ])
  ]))
}