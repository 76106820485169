
import { defineComponent, PropType, computed } from 'vue';
import Icon from '@/components/common/icon.vue';
import { AddressItem } from '@/types/address';
import { useCountry } from '@/hooks/country';
import { useJumpPage } from '@/hooks/jumpPage';
export default defineComponent({
  name: 'AddressItem',
  components: {
    Icon,
  },
  emits: ['clickAddress'],
  props: {
    addressItem: {
      type: Object as PropType<AddressItem>,
      required: true,
      defalut: null,
    },
    isShowEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { getCountryInfoById } = useCountry();
    const countryInfo = computed(() => getCountryInfoById(props.addressItem.country));
    const { navAddressDetail }  = useJumpPage();
    return {
      countryInfo,
      clickAddress: () => {
        ctx.emit('clickAddress', props.addressItem);
      },
      navAddressDetail,
    };
  },
});
