
import { defineComponent, ref, computed, watch } from 'vue';
import Icon from '@/components/common/icon.vue';
import CountryPicker from '@/components/common/country-seletor.vue';
import { useCountry } from '@/hooks/country';
import { useEditAddress } from '@/hooks/address';
import { IsDefaultUserAddress } from '@/types/address';
import Picker from '@/components/common/picker.vue';
export default defineComponent({
  name: 'AddressEdit',
  components: {
    Icon,
    CountryPicker,
    Picker,
  },
  setup() {
    const { getCountryInfoById } = useCountry();
    const { form, phonePrefix, saveAddress } = useEditAddress();
    const isShowCountryPicker = ref(false);
    const isShowTownPicker = ref(false);
    const displayCountryInfo = computed(() =>
      getCountryInfoById(form.value.country)
    );
    const townList = computed(() => {
      const curCountry = getCountryInfoById(form.value.country);
      return curCountry?.states.map((item) => ({
        id: item.id,
        name: `${item.name} ${item.nameCn}`,
        value: item.id,
      }));
    });
    watch(
      () => form.value.country,
      () => {
        if (townList.value) {
          const defaultOne = townList.value[0];
          form.value.state = defaultOne.id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const displayTownInfo = computed(() => {
      const res = townList.value?.find((item) => item.id === form.value.state);
      if (!res) {
        return;
      }
      return res;
    });
    return {
      IsDefaultUserAddress,
      form,
      townList,
      phonePrefix,
      displayCountryInfo,
      displayTownInfo,
      isShowCountryPicker,
      isShowTownPicker,
      onCloseCountryPicker: () => {
        isShowCountryPicker.value = false;
      },
      showCountryPicker: () => {
        isShowCountryPicker.value = true;
      },
      onCloseTownPicker: () => {
        isShowTownPicker.value = false;
      },
      showTownPicker: () => {
        isShowTownPicker.value = true;
      },
      saveAddress,
    };
  },
});
