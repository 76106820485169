
import { defineComponent } from 'vue';
import AddressItem from '@/components/address/address-item.vue';
import { useJumpPage } from '@/hooks/jumpPage';
import { useAddressList } from '@/hooks/address';
export default defineComponent({
  name: 'AddressList',
  components: {
    AddressItem,
  },
  setup() {
    const { navAddressDetail } = useJumpPage();
    const  { addressList, pageParams, clickAddress } = useAddressList();
    return {
      navAddressDetail,
      addressList,
      pageParams,
      clickAddress,
    };
  },
});
